/* commonly used inline styles */

/* flex positioning */

.flex {
  display: flex !important;
}

.flex-inline {
  display: inline-flex !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.items-stretch {
  align-items: stretch !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-end {
  align-self: flex-end !important;
}

.self-center {
  align-self: center !important;
}

.self-baseline {
  align-self: baseline !important;
}

.self-stretch {
  align-self: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-evenly {
  justify-content: space-around !important;
}

.content-start {
  align-content: flex-start !important;
}

.content-end {
  align-content: flex-end !important;
}

.content-center {
  align-content: center !important;
}

.content-between {
  align-content: space-between !important;
}

.content-around {
  align-content: space-around !important;
}

.content-stretch {
  align-content: stretch !important;
}
.text-start {
  text-align: start !important;
}
.text-center {
  text-align: center !important;
}
.text-end {
  text-align: end !important;
}

/* flex gaps */

.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-12 {
  gap: 3rem;
}
.gap-16 {
  gap: 4rem;
}
.gap-20 {
  gap: 5rem;
}

.nowrap {
  flex-wrap: nowrap !important;
}

/* widths and heights */

.w-0 {
  width: 0 !important;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-10 {
  width: 2.5rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-32 {
  width: 8rem;
}
.w-40 {
  width: 10rem;
}
.w-48 {
  width: 12rem;
}
.w-56 {
  width: 14rem;
}
.w-64 {
  width: 16rem;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}

.h-0 {
  height: 0;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-32 {
  height: 8rem;
}
.h-40 {
  height: 10rem;
}
.h-48 {
  height: 12rem;
}
.h-56 {
  height: 14rem;
}
.h-64 {
  height: 16rem;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}

.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-8 {
  padding: 2rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-12 {
  padding: 3rem;
}
.p-14 {
  padding: 3.5rem;
}
.p-16 {
  padding: 4rem;
}
.p-20 {
  padding: 5rem;
}

.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-8 {
  margin: 2rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-12 {
  margin: 3rem;
}
.m-14 {
  margin: 3.5rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
